import React from 'react'

export const Popup = () => {
  return (
    <div className="contact__popup">
      <div className="contact__popup__inner">
        <span>Your message has bees successfully send 👍 </span>
      </div>
    </div>
  );
}

